<template>
    <div class="productDetail" >
        <loader v-if="loader"/>
        <div
        class="a-page" id="a-page"
        data-router-view="renderer"
        data-transition-in="default"
        >
        <loader v-if="!products && products == ''"/>
        <header class="s-header-product" id="s-header-product" style="">
            <div class="s-header-breadcrumb">
                <h3 class="f-st">
                    <span
                    ><a href="#/collection/">Collection</a></span
                    >
                    <svg viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                    ></path>
                    </svg>
                    <span
                    ><a :href="'#/collection/'+slug">{{trimName(slug)}}</a></span
                    >
                    <svg viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                    ></path>
                    </svg>
                    <span>{{trimName(products.name)}}</span>
                </h3>
            </div>
            <div class="s-header-heading">
                <h2 class="f-hm">{{trimName(products.name)}}<em>—{{trimName(slug)}}</em></h2>
            </div>
            <div class="s-header-info">
                <a @click="activeDescription = !activeDescription" class="f-prr description" v-if="products && products.product_detail && products.product_detail[0].description">
                    <p class="f-pr">
                        <b>Description: </b>
                    </p>
                    <span class="material-icons">
                        keyboard_arrow_down
                    </span>
                </a>
                <p  v-if="products && products.product_detail && products.product_detail[0].description && activeDescription" class="f-pr descrip" v-html="products.product_detail[0].description"></p>
                <div class="f-prr" v-if="products.specification">
                    <p class="f-pr" v-for="(specifications,i) in products.specification" :key="i">
                        <b>{{specifications.title}}:</b> {{specifications.value}}
                    </p>
                </div>
                <!-- <div class="f-prr sizeChart" v-if="products && products.size_chart" v-on:click="openPop()">
                    <p class="">
                        <b>Size: </b> <em> View Size Chart </em>
                    </p>
                </div> -->
                <div v-if="products && products.variants_classification&& products.variants_classification[0].price && products.variants_classification[0].price != 0 " v-for="(variants,ind) in products.variants_definitions" :key="ind" class="section">
                    <p class="f-pr">
                        <b>Please select {{variants.title}}</b> 
                    </p>
                    <div style="display: flex;flex-wrap: wrap;">
                        <p v-for="(sub,i) in variants.value" :key="i" class="size-option">
                            <label class="option">
                                <input type="radio" :value="sub.title" v-model="products[variants.title]" @change="selectedVariants(variants.title, products[variants.title])">
                                <div class="sizeBox">
                                <p>{{sub.title}}</p>
                                </div>
                            </label>
                        </p>
                    </div>
                </div>
                <div class="f-prr" v-if="products.price">
                    <p class="f-pr" v-if="currency == 'USD'">
                        <b>PRICE:</b> {{currency}} <span >{{convertCurrency(products.price)}}/-</span>  
                    </p>
                    <p class="f-pr" v-if="currency == 'PKR'">
                        <b>PRICE:</b> {{currency}} <span >{{formatMoney(products.price)}}/-</span>  
                    </p>
                </div>
                <p id="showmessage">Your message has been successfully sent.</p>
                <form v-on:submit="sendQuery(products)" class="f-contact" v-if="products && products.variants_classification && products.variants_classification[0].price && products.variants_classification[0].price == 0 || !products.variants_classification[0].price ">
                    <p class="f-prr">Contact With Our Fashion Expert</p>
                    <input type="text" class="f-contact-m" placeholder="Name" v-model="obj.name" required>
                    <input type="email" class="f-contact-m" placeholder="Email" v-model="obj.email" required>
                    <input type="number" class="f-contact-l" placeholder="Phone No" v-model="obj.phone_no" required>
                    <textarea class="f-contact-t-a" placeholder="Message" v-model="obj.message" required/>
                    <button  class="f-contact-btn" type="submit"><p class="f-prr">Send Message</p></button>
                </form>
                <button @click="addCart(products)" v-if="products && products.variants_classification && products.variants_classification[0].price && products.variants_classification[0].price != 0 " class="f-bs" data-id="1731">
                    <svg class="f-bs-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                    ></path>
                    </svg>
                    <svg class="f-bs-arrow" viewBox="0 0 6 10">
                    <path
                        d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
                    ></path>
                    </svg>
                    <span class="f-bs-line"></span> <i class="f-bs-label">Add to Cart</i>
                </button>
            </div>
        </header>
        <section class="s-product" >
            <div class="s-product-gallery" id="s-product-gallery">
                <img
                    v-for="(images,ind) in products.images" :key="ind"
                    v-if="images && images.image && images.image[0].src"
                    :src="$store.state.file_url + images.image[0].src + '?alt=media'"
                    alt=""
                    style="opacity: 1; transform: translate(0px, 0px);"
                />
            </div>
            <div class="s-product-background" data-background="#ffffff"></div>
        </section>
        </div>
        <!-- <div class="size_chart" v-if="popUp">
            <div class="cls" v-on:click="openPop()">x</div>
            <carousel class="swiper" :per-page="1" :navigate-to="3"  :paginationEnabled="true" :mouse-drag="true">
            <slide v-for="(itm,ind) in products.size_chart" :key="ind" class="slide" :style="'background-image: url('+$store.state.file_url + itm.image[0].src + '?alt=media'+')'">
            </slide>
        </carousel>
        </div> -->
        <featuredProducts/>
        <div id="showSnackbar">Your message has been successfully sent.</div>
        <div v-if="show_size_pop" class="section-bottom">
            <div class="left">
                <p class="heading">{{size_chart_data.name}}</p>
                <p id="showmessage">Your message has been successfully sent.</p>
                <form v-on:submit="sizeChart(products)" class="f-contact">
                    <!-- <div> -->
                        <div v-for="(field,i) in size_chart_data.fields" :key="i" style="width: 49%; margin-bottom:5px">
                            <input v-if="field.type == 'Number'" type="number" step="0.01" class="f-contact-m" :placeholder="field.label" v-model="products.size_chart[field.title]"/>
                            <input v-if="field.type == 'Text'" type="number" step="0.01" class="f-contact-m" :placeholder="field.label" v-model="products.size_chart[field.title]"/>
                        </div>
                    <!-- </div> -->
                    <button class="f-contact-btn" type="submit">
                        <p class="f-prr" id="save-btn" >Submit</p>
                    </button>
                </form>
            </div>
            <div class="right">
                <img :src="this.$store.state.file_url+size_chart_data.image[0].src + '?alt=media'" alt="">
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'Home',
    components:{
        featuredProducts: () => import('../components/featuredProducts'),
        loader: () => import('../components/loader.vue'),
        Carousel,
        Slide,
    },
  data(){
    return {
      state: 'description',
      show: false,
      products: {},
      colours: {},
      qty_active: false,
      out_of_stock: false,
      col:'',
      id:'',
      slug:'',
      _variants:{},
      product_quantity: 1,
      loader: true,
      slide: 0,
      max_scroll: 0,
      obj: {},
      currency: '',
      popUp: false,
      activeDescription: false,
      show_size_pop: false,
      size_chart_data: {}
    }
  },
  watch: {
      "$route.params": {
      handler(newValue, preValue) {
        //   this.init()
      },
      immediate: true
      }
  },
  created(){
    this.init();
    if(localStorage.getItem('currency')){
        this.currency = JSON.parse(localStorage.getItem('currency')) 
    }
  },
  methods: {
    openPop(){
        if(!this.popUp){
            this.popUp = true
        }else{
            this.popUp = false
        }
    },
    init(){
        this.$store.dispatch('Store')
        this.slug = this.$route.params.slug;
        this.id = this.$route.params.id;
        this.$store.dispatch("ProductRead", {
            bool: {
                should: [
                {
                    match_phrase: {
                    event: this.$store.state.event
                    }
                },
                {
                    match_phrase: {
                    "_id": this.id
                    }
                }
                ],
                minimum_should_match: 2
            }  
        })
        .then(response => {
            setTimeout(() => {
                this.products = response[0]
                this.products.selected_variants = {}
                this.products.size_chart = {}
                this.products.product_quantity = 1;
                console.log(this.products, 'product')
                this.products.product_quantity = 1;
                if(this.products && this.products.variants_definitions && this.products.variants_definitions[0]){
                    this.products[this.products.variants_definitions[0].title] = this.products.variants_definitions[0].value[0].title
                }
                if(this.products && this.products.variants_definitions && this.products.variants_definitions[1]){
                    this.products[this.products.variants_definitions[1].title] = this.products.variants_definitions[1].value[0].title
                }
                if(this.products && this.products.variants_definitions && this.products.variants_definitions[0]){
                    this.selectedVariants(this.products.variants_definitions[0].title , this.products.variants_definitions[0].value[0].title)
                   
                }
                if(this.products && this.products.variants_definitions && this.products.variants_definitions[1]){
                    this.selectedVariants(this.products.variants_definitions[1].title , this.products.variants_definitions[1].value[0].title)
                }
                this.loader = false
            }, 200);
            setTimeout(() => {
                window.addEventListener('scroll', this.handleScroll);
                if(document.getElementById('s-product-gallery')){
                    var element = document.getElementById('s-product-gallery');
                    this.max_scroll = element.clientHeight - 500
                }
            }, 1000)
        })
        
    },
    selectedVariants(selected, value){
        // console.log(selected, value, this.products , 'selectedVariants')
        this.products.selected_variants[selected] = value
        // console.log(this.products.selected_variants, 'this.products.selected_variants')
        if(this.products.variants_definitions.length == Object.keys(this.products.selected_variants).length){
            this.qty_active = true
            var data = this.products.variants_classification
            var selected_variants = Object.values(this.products.selected_variants).sort()
            var selectedVariable = ''
            var classificationVariable = ''
            for(var ind in data){
              var s = data[ind].title
              var match = {}
              match = s.split(',')
              var i = Object.values(match).sort()
              if(JSON.stringify(i) === JSON.stringify(selected_variants) ){
                // console.log(i, selected_variants)
                  this.products.product_quantity = 1
                  this.products.price = data[ind].price
                  this.products.serving = data[ind].serving
                  this.products.cooking_time = data[ind].cooking_time
                  if(data && data[ind].compare_price != 0){
                    this.products.compare_price = data[ind].compare_price
                  }
                  this.products.sku = data[ind].sku
                  // console.log(this.products.price, this.products.serving, this.products.cooking_time )
              }
            }
        }
        var a = this.products
        this.products = {}
        this.products = a
    },
    addToCart(product) {
      ga('require', 'ecommerce'); //require
      ga('ecommerce:addItem', {
          id: product.id,                     
          name: product.name,    
          category: 'cart',         
          price: product.price,                
          quantity: product.product_quantity                   
      });      
      ga('ecommerce:send');     // Send data using an event.
    },
    addCart(product){
        if(this.products && this.products.variants_definitions && this.products.variants_definitions.length == Object.keys(this.products.selected_variants).length){
            if(product.custom_size_chart != 'Yes'){
                var params = { action: "add", product , product };
                this.$store.dispatch("Cart", params);
                this.addToCart(product)
            }else{
                axios.post(this.$store.state.api+'size_chart/_search',{
                    from: 0,
                        size: 5000,
                        query: {
                            bool: {
                                must: [
                                    {
                                        match_phrase: {
                                            event: this.$store.state.event
                                        }
                                    },
                                    {
                                        match_phrase: {
                                            name: product.size_chart_name
                                        }
                                    }
                                ]
                            }
                        }
                })
                .then(res => {
                    this.size_chart_data = {}
                    this.size_chart_data = res.data.hits.hits[0]._source
                    console.log(this.size_chart_data, 'size-chart')
                    this.show_size_pop = true
                })
            }
        }else if(this.products && !this.products.variants_definitions){
            this.products.price = 0
            var params = { action: "add", product , product };
            this.$store.dispatch("Cart", params);
            this.addToCart(product)
        }else{
            alert('Please Select Variants')
        }
    },
    sizeChart(product){
        event.preventDefault()
        console.log(product)
        if(this.products && this.products.variants_definitions && this.products.variants_definitions.length == Object.keys(this.products.selected_variants).length){
            var params = { action: "add", product , product };
            this.$store.dispatch("Cart", params);
            this.addToCart(product)
            this.show_size_pop = false
        }else if(this.products && !this.products.variants_definitions){
            this.products.price = 0
            var params = { action: "add", product , product };
            this.$store.dispatch("Cart", params);
            this.addToCart(product)
        }else{
            alert('Please Select Variants')
        }
    },
    sendQuery(product){
        event.preventDefault();
        this.obj.product_name = product.name
        this.obj.status = 'Pending'
        this.obj.createdAt = moment();
        this.obj.event = this.$store.state.event
        if(product && product.images && product.images[0].image[0]){
            this.obj.product_image = product.images[0].image[0]
        }
        if(product && product.collections && product.collections[0].slug){
            this.obj.collection = product.collections[0].slug
        }
        axios
        .post(
            this.$store.state.api + "order_query/_doc",
            this.obj
        ).then(response => {
            localStorage.setItem('orderqueryid', JSON.stringify(response.data._id))
            var x = document.getElementById("showmessage");
            x.className = "active";
            axios.post(this.$store.state.api + "send_email", {
                user:{
                    email: this.obj.email,
                },
                subject: "Order query for " + this.obj.product_name,
                object: {
                    name: this.obj.name,
                    product_name: this.obj.product_name,
                    subject: "Query for " + this.obj.product_name,
                },
                bcc: [
                    "arwashahid052@gmail.com",
                ],
                from: "info@virtualeye.online",
                template_id: "d-e6002d09fd944ccb800992ec2de64615"
            });
            setTimeout(function(){ 
                x.className = x.className.replace("showSnack", ""); 
            }, 3000);
            this.obj = {}
        })
        // var data = {}
        // data.product_name = product.name
    },
    handleScroll(){
            if(window.scrollY < 50){
                if(document.getElementById("s-header-product")){
                    document.getElementById("s-header-product").style.transform = 'translate3d(0px, 0px, 0px)'
                }

            }else if(window.scrollY > 50 && window.scrollY < this.max_scroll ) {
                if(document.getElementById("s-header-product")){
                    document.getElementById("s-header-product").style.transition = '.2s all ease'
                    document.getElementById("s-header-product").style.transform = 'translate3d(0px, '+ (window.scrollY - 100)+'px, 0px)'
                }
            }else if(window.scrollY >= this.max_scroll){
                if(document.getElementById("s-header-product")){
                    document.getElementById("s-header-product").style.transform = 'translate3d(0px, '+this.max_scroll+'px, 0px)'
                }
            }       
    },
    trimName(name) {
        if (name)
        return name
        .replace(new RegExp("_", "g"), " ")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        .toUpperCase();
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
        }
    },
    convertCurrency(amount) {
        var price = false
        var c = false
        if(localStorage.getItem('currencyValue')){
            c = JSON.parse(localStorage.getItem('currencyValue'))
        }
        price =  Math.round(amount/c)
        return price
    },
  }
}
</script>


<style scoped>
.productDetail{
    min-height: 100vh !important;
}
.f-contact{
    width: 100%;
    padding: 1%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.f-contact .f-contact-m{
    width: 100%;
    height: 3vw;
    color: #1f1f1f;
    font-size: 0.938vw;
    font-weight: normal;
    font-family: 'Aktiv Grotesk';
    outline: 0;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 0 1.25vw;
    line-height: 3.563vw;
    letter-spacing: 0.034vw;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid #d2d2d2;
    width: 48%;
    margin: 1%;
}
.f-contact .f-contact-l{
    height: 3vw;
    color: #1f1f1f;
    font-size: 0.938vw;
    font-weight: normal;
    font-family: 'Aktiv Grotesk';
    outline: 0;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 0 1.25vw;
    line-height: 3.563vw;
    letter-spacing: 0.034vw;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid #d2d2d2;
    width: 99%;
    margin: 1%;
}
.f-contact .f-contact-t-a{
    height: 6vw;
    color: #1f1f1f;
    font-size: 0.938vw;
    font-weight: normal;
    font-family: 'Aktiv Grotesk';
    outline: 0;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 0 1.25vw;
    line-height: 3.563vw;
    letter-spacing: 0.034vw;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid #d2d2d2;
    width: 99%;
    margin: 1%;
}
.f-contact .f-contact-btn{
    width: 98%;
    margin: 1% 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #232323 !important;
    color: #fff;
    padding: 4% 0
}
.f-contact .f-prr{
    margin: 4% 1%;
    font-family: 'Saol Display';
}
.f-contact .f-contact-btn .f-prr{
    margin: 0;
    font-family: 'Aktiv Grotesk';
}
.f-contact .f-contact-m{
    width: 100%;
    height: 3vw;
    color: #1f1f1f;
    font-size: 0.938vw;
    font-weight: normal;
    font-family: 'Aktiv Grotesk';
    outline: 0;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    padding: 0 1.25vw;
    line-height: 3.563vw;
    letter-spacing: 0.034vw;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid #d2d2d2;
}
.sticky{
    position: fixed;
}
.f-prr{
    margin-top: 1.375vw;
}
.f-prr .f-pr{
    margin-top: 0 !important;
    text-transform: capitalize;
}
.f-pr select{
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ae98a2;
    border-radius: 5px;
    background: transparent;
    color: #ae98a2;
}
#cursor div{
    z-index: 9999 !important;
}
.s-header-info{
    width: 100%;
    margin-left: 0;
}
.visibil{
    visibility: inherit !important;
    transform: translateX(-100px);
    animation-delay: 10s;
    animation: come-right-fade 2s ease forwards;
}
.cursor-visibil{
    animation-delay: 20s;
    opacity: 1 !important;
}
.s-product-gallery{
    margin: 0.313vw calc(100vw / 20 * 4) 0 calc(100vw / 20 * 9);
    /* height: 80vh;
    overflow: scroll; */
}
.section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-top: 2.375vw; */
}
.size-option{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.size-option .option{
    position: relative; 
    cursor: pointer;
}
.option input[type="radio"]{
    margin: 0;
    display: none;
    border: none;
}
.size-option .sizeBox{
    width: 80px;
    height: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ae98a2;
    margin-left: 0;
}
.size-option .sizeBox p{
    font-size: 10px;
    font-family: 'Saol Display';
    text-transform: uppercase;
    text-align: center;
}
.size-option .sizeBox:hover{
    border: 1px solid #ae98a2;
    background: #ae98a2;
    color: #fff;
}
.size-option .option input[type="radio"]:checked ~ .sizeBox {
    border: 1px solid #ae98a2;
    background: #ae98a2;
    color: #fff;
}
.size-option .options p{
    font-size: 16px;
}
@keyframes come-right-fade {
  to { 
      transform: translateX(0); 
    }
    0%{ opacity: 0 }
    100%  { opacity: 1 }
}

.a-page{
    transform: translateY(-100px);
    animation-delay: 10s;
    animation: come-in-fade 2s ease forwards;
}
.s-header-product .f-bs:hover .f-bs-arrow, 
.s-header-product .f-bs:hover .f-bs-line{
    animation: right-left .5s ease-in-out forwards;
}
.s-header-product .f-bs:hover .f-bs-label{
    animation: left-right .5s ease-in-out forwards;
}
@keyframes right-left{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(80px, 0px);
    }
}
@keyframes left-right{
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(-70px, 0px);
    }
}
@keyframes come-in-fade {
  to { 
      transform: translateY(0); 
    }
    0%{ opacity: 0 }
    100%  { opacity: 1 }
}
@media only screen and (max-width: 580px){
    .a-page{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .s-product-gallery{
        margin: 0 calc(100vw / 12 * 1);
        height: auto;
    }
    
    .s-header-product .f-hm{
        font-size: 8.5vw !important;
    }
    .f-contact .f-contact-l,
    .f-contact .f-contact-m{
        height: 6vw;
        font-size: 1.938vw;
        padding: 0 2.25vw;
    }
    .f-contact .f-contact-t-a{
        height: 12vw;
        font-size: 1.938vw;
        padding: 0 2.25vw;
    }
    #showSnackbar {
    /* min-width: 250px; */
        margin-left: -180px !important;
    }
}
.s-header-product .f-hm{
    font-size: 2.5vw;
    margin-bottom: 0;
}
section, figure{
    margin: 0;
    margin-bottom: 0px;
}
.f-st{
    overflow: hidden;
}

#showSnackbar {
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -15%;
    font-size: 17px;
    transition: all 500ms;
    transform: translate(0,0);
    opacity: 1;
}

#removeSnackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 25px;
    font-size: 17px;
}

#showmessage{
    background: #d2e4c4;
    color: #307a07;
    padding: 10px 20px;
    font-size: 14px;
    display: none;
    margin-top: 10px;
}


.active {
   /* transform: translateY(-100px) !important; */
   display: flex !important;
}
.s-header-product .f-pr{
    margin-top: 1.375vw;
}
.s-header-breadcrumb{
    margin-bottom: 2.25vw;
}

.size_chart{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    background: #00000067;
    z-index: 999
}
.size_chart img{
    width: 415px;
}
.swiper{
    width: 50%;
    margin: auto;
}
.slide{
    width: 100%;
    height: 75vh !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}
.size_chart .cls{
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    margin: 20px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    font-weight: 700;
    box-shadow: 0px 0px 18px -2px #ccc;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup.f-bs-label{
    animation: none
}
.sizeChart em{
    text-decoration: underline;
}
.description{
    width: 32%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-top: 0px;
}
.descrip{
    line-height: 1.25rem;
    margin-top: .6rem !important;
}

.section-bottom {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 30px;
  left: 150px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 15px 1px #ccc;
  background-color: #fff;
  max-height: 90vh;
  z-index: 999;
  border-radius: 20px;
}
.section-bottom .left {
  width: 50%;
  min-width: 40%;
  padding: 5%;
}
.section-bottom .left h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.section-bottom .right {
  width: 50%;
  min-width: 40%;
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.section-bottom .right h4{
    margin: 5% 0;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: inherit;
    font-family: "Saol Display";
    margin-bottom: .5rem;
    line-height: 1.1;
    color: inherit;
    margin-top: 0;
}
.section-bottom .left .heading{
    margin-top: 1.375vw;
    margin-left: 7px;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 5px;
}
.f-contact {
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>